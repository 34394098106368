import { useRef, memo, forwardRef, useImperativeHandle } from "react";

import Modal, { ModalRef, ModalProps } from "../Modal";

import type { RefObject } from "react";

export type NumberKeyboardProps = ModalProps & {
  modalRef?: RefObject<ModalRef>;
};
export type NumberKeyboardRef = {
  getValue: () => string;
};
const NumberKeyboard = forwardRef<NumberKeyboardRef, NumberKeyboardProps>((props, keyboardRef) => {
  const { modalRef, children, ...cProps } = props;
  const ref = useRef<ModalRef>(null);
  const _modalRef = modalRef || ref;

  useImperativeHandle(
    keyboardRef,
    () => ({
      getValue() {
        return "";
      },
    }),
    [],
  );

  return (
    <Modal
      {...cProps}
      ref={_modalRef}
    >
      {children}
    </Modal>
  );
});
NumberKeyboard.displayName = "NumberKeyboard";

export default memo(NumberKeyboard);
