import { getWindow, getDocument } from "ssr-window";
import { getSupport } from "./getSupport";

type Device = {
  ios: boolean;
  android: boolean;
  androidChrome: boolean;
  wechat: boolean;
  desktop: boolean;
  iphone: boolean;
  ipod: boolean;
  ipad: boolean;
  edge: boolean;
  ie: boolean;
  firefox: boolean;
  macos: boolean;
  windows: boolean;
  webview?: boolean;
  miniprogram?: boolean;
  os?: string | null;
  pixelRatio: number;
  osVersion?: string | null;
  prefersColorScheme: () => string | undefined;
};
let deviceCalculated: Device;

function calcDevice({ userAgent }: { userAgent?: string } = {}) {
  const support = getSupport();
  const window = getWindow();
  const platform = window.navigator.platform;
  const ua = userAgent || window.navigator.userAgent;

  const device: Device = {
    ios: false,
    android: false,
    wechat: false,
    androidChrome: false,
    desktop: false,
    iphone: false,
    ipod: false,
    ipad: false,
    edge: false,
    ie: false,
    firefox: false,
    macos: false,
    windows: false,
    pixelRatio: 1,
    prefersColorScheme: () => "light",
  };
  // 是否为微信浏览器
  const wechatMatch = ua.toLowerCase()?.match(/MicroMessenger/i);
  device.wechat = wechatMatch !== null && wechatMatch[0] === "MicroMessenger";

  device.miniprogram = window.__wxjs_environment === "miniprogram";

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const android = ua.match(/(Android);?[\s/]+([\d.]+)?/);
  let ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  const ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
  const iphone = !ipad && ua.match(/(iPhone\sOS|iOS|iPhone;\sCPU\sOS)\s([\d_]+)/);
  const ie = ua.indexOf("MSIE ") >= 0 || ua.indexOf("Trident/") >= 0;
  const edge = ua.indexOf("Edge/") >= 0;
  const firefox = ua.indexOf("Gecko/") >= 0 && ua.indexOf("Firefox/") >= 0;
  const windows = platform === "Win32";
  let macos = platform === "MacIntel";

  // iPadOs 13 fix
  const iPadScreens = [
    "1024x1366",
    "1366x1024",
    "834x1194",
    "1194x834",
    "834x1112",
    "1112x834",
    "768x1024",
    "1024x768",
    "820x1180",
    "1180x820",
    "810x1080",
    "1080x810",
  ];
  if (
    !ipad &&
    macos &&
    support.touch &&
    iPadScreens.indexOf(`${screenWidth}x${screenHeight}`) >= 0
  ) {
    ipad = ua.match(/(Version)\/([\d.]+)/);
    if (!ipad) {
      // @ts-ignore
      ipad = [0, 1, "13_0_0"];
    }
    macos = false;
  }

  device.ie = ie;
  device.edge = edge;
  device.firefox = firefox;

  // Android
  if (android) {
    device.os = "android";
    device.osVersion = android[2];
    device.android = true;
    device.androidChrome = ua.toLowerCase().indexOf("chrome") >= 0;
  }
  if (ipad || iphone || ipod) {
    device.os = "ios";
    device.ios = true;
  }
  // iOS
  if (iphone && !ipod) {
    device.osVersion = iphone[2].replace(/_/g, ".");
    device.iphone = true;
  }
  if (ipad) {
    device.osVersion = ipad[2].replace(/_/g, ".");
    device.ipad = true;
  }
  if (ipod) {
    device.osVersion = ipod[3] ? ipod[3].replace(/_/g, ".") : null;
    device.ipod = true;
  }
  // iOS 8+ changed UA
  if (device.ios && device.osVersion && ua.indexOf("Version/") >= 0) {
    if (device.osVersion.split(".")[0] === "10") {
      device.osVersion = ua.toLowerCase().split("version/")[1].split(" ")[0];
    }
  }

  // Webview
  device.webview =
    !!(
      (iphone || ipad || ipod) &&
      (ua.match(/.*AppleWebKit(?!.*Safari)/i) || (window.navigator as any).standalone)
    ) ||
    (window.matchMedia && window.matchMedia("(display-mode: standalone)").matches);

  // Desktop
  device.desktop = !(device.ios || device.android);
  if (device.desktop) {
    device.macos = macos;
    device.windows = windows;
    if (device.macos) {
      device.os = "macos";
    }
    if (device.windows) {
      device.os = "windows";
    }
  }

  // Pixel Ratio
  device.pixelRatio = window.devicePixelRatio || 1;

  // Color Scheme
  const DARK = "(prefers-color-scheme: dark)";
  const LIGHT = "(prefers-color-scheme: light)";
  device.prefersColorScheme = function prefersColorTheme() {
    let theme;
    if (window.matchMedia && window.matchMedia(LIGHT).matches) {
      theme = "light";
    }
    if (window.matchMedia && window.matchMedia(DARK).matches) {
      theme = "dark";
    }
    return theme;
  };

  // Export object
  return device;
}

const IS_BROWSER = (() => {
  const document = getDocument();
  try {
    // eslint-disable-next-line no-restricted-globals
    return Boolean(
      document &&
        document.body &&
        document.body.getBoundingClientRect &&
        document.body.getBoundingClientRect().width > 0,
    );
  } catch (e) {
    console.log(e);
    return false;
  }
})();

function getDevice(overrides = {}, reset = IS_BROWSER) {
  if (!deviceCalculated || reset) {
    deviceCalculated = calcDevice(overrides);
  }
  return deviceCalculated;
}

export { getDevice };
