import { cloneDeep } from "lodash-es";

/**
 * 获取链接地址参数
 * @param key
 */
export function matchQuery(key: string) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

/**
 * 保持两位小数点
 * @param num
 * @param decimal
 */
export function numberDecimal(num: number | string, decimal = 2) {
  let result = parseFloat(num.toString());
  if (Number.isNaN(result)) {
    return num;
  }
  result = Math.round(+num * 100) / 100;
  let tempStr = result.toString();
  let posDecimal = tempStr.indexOf(".");
  if (posDecimal < 0) {
    posDecimal = tempStr.length;
    tempStr += ".";
  }
  while (tempStr.length <= posDecimal + decimal) {
    tempStr += "0";
  }
  return tempStr;
}

/**
 * 卡号格式化
 * @param value
 */
export const cardNumberFormat = (value: number) => String(value || "").replace(/.*(\d{4})$/, "$1");

/**
 * 格式化有效期
 * @param expireDate
 */
export function formatExpireDate(expireDate: string) {
  const endDate = new Date(expireDate.replace(/-/g, "/"));
  const currentDate = new Date();
  const timeDiff = endDate.getTime() - currentDate.getTime();
  const daysUntilExpire = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) + 1;
  if (daysUntilExpire > 3) {
    return `有效期还有${daysUntilExpire}天`;
  } else if (daysUntilExpire === 3) {
    return "后天到期";
  } else if (daysUntilExpire === 2) {
    return "明天到期";
  } else {
    return "今天到期";
  }
}

/**
 * 身份证号校验
 * @param value
 */
export function isIdentityNumber(value: string) {
  return /^\d{17}(\d|x|X)$/g.test(String(value)) || /^\d{15}$/g.test(String(value));
}

/**
 * 去除对象中键值为 null, undefined, 同时去除字符串首尾空格
 */
export const nonNullableObject = <T extends Record<string, any>>(record: T): T => {
  if (!record) return {} as T;
  const cloneObject = cloneDeep(record);
  const handleObject = <P>(value: P): P => {
    if (typeof value === "string") {
      // 去除首尾空格
      return value.trim() as P;
    }
    if (Array.isArray(value)) {
      return value.map(handleObject).filter((i) => i !== null && i !== undefined && i !== "") as P;
    }
    if (value !== null && typeof value === "object") {
      return Object.entries(value).reduce(
        (acc, [key, value]) => {
          const cleanedValue = handleObject(value);
          if (
            cleanedValue !== null &&
            cleanedValue !== undefined &&
            cleanedValue !== "" &&
            JSON.stringify(cleanedValue) !== "{}" &&
            JSON.stringify(cleanedValue) !== "[]"
          ) {
            acc[key] = cleanedValue;
          }
          return acc;
        },
        {} as Record<string, any>,
      ) as P;
    }
    return value;
  };
  return handleObject(cloneObject);
};
