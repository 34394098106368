import { Redirect, Route } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";

import { defineRoutes } from "src/router";

import { Sentry, history } from "@union/core";
const SentryRoute = Sentry.withSentryRouting(Route);

setupIonicReact({
  mode: "ios",
  statusTap: true,
  backButtonText: "返回",
});

const App = () => {
  return (
    <IonApp>
      <IonReactRouter history={history}>
        <IonRouterOutlet>
          {defineRoutes.map((route) => (
            <SentryRoute
              exact={true}
              strict={true}
              path={route.path}
              key={route.pagePath}
              component={Sentry.withProfiler(route.element, {
                name: route.path.split("/").at(-2),
              })}
            />
          ))}
          <SentryRoute
            exact
            path="/"
          >
            <Redirect to="/home" />
          </SentryRoute>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
