import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";

import { captureSourceLoad, rewriteConsoleError } from "./capture";

export const history = createBrowserHistory();

export type SentryInitOptions = {
  environment: string;
  dsn: string;
  enabled: boolean;
};
export const sentryInit = (options: SentryInitOptions) => {
  Sentry.init({
    // 本地开发不进行上报
    enabled: options.enabled ?? false,
    environment: options.environment,
    dsn: options.dsn,
    integrations: [
      Sentry.httpClientIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(), // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],
    release: process.env.release,
    normalizeDepth: 10,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "/http?://localhost:4335/",
      /https?:\/\/(fat|dev|sit)?-vcs.h5.vcredit-t\.com\.local/,
      /https?:\/\/vcspro\.vcredit\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  captureSourceLoad({ env: options.environment });
  rewriteConsoleError({ env: options.environment });
};

export default Sentry;
