import { IonModal, IonToolbar, IonButtons, IonButton, IonContent, IonTitle } from "@ionic/react";
import { useRef, memo, forwardRef, useImperativeHandle } from "react";

import type { PropsWithChildren } from "react";
import type { GetProps } from "../../model/getProps";

export type ModalRef = {
  modalElement: HTMLIonModalElement | null;
  toggle: (isOpen?: boolean) => void;
};
export type ModalProps = PropsWithChildren<
  Omit<GetProps<typeof IonModal>, "ref"> & {
    title?: string;
    /**
     * 显示操作栏
     */
    showHeader?: boolean;
    onCancel?: () => void;
    onConfirm?: () => void;
  }
>;

const Modal = forwardRef<ModalRef, ModalProps>((props, ref) => {
  const modalRef = useRef<HTMLIonModalElement>(null);
  const {
    title,
    children,
    keepContentsMounted = false,
    showHeader = true,
    onCancel,
    onConfirm,
    handle = false,
    ...restProps
  } = props;

  useImperativeHandle(
    ref,
    () => ({
      modalElement: modalRef.current,
      toggle: (isOpen = false) => {
        if (modalRef.current) {
          return modalRef.current[isOpen ? "present" : "dismiss"]();
        }
      },
    }),
    [],
  );

  return (
    <IonModal
      keepContentsMounted={keepContentsMounted}
      {...restProps}
      ref={modalRef}
      handle={handle}
    >
      {showHeader && (
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="dark"
              size="small"
              onClick={() => {
                onCancel?.();
              }}
            >
              取消
            </IonButton>
          </IonButtons>
          {title && <IonTitle className="text-sm">{title}</IonTitle>}
          <IonButtons slot="end">
            <IonButton
              color="dark"
              size="small"
              onClick={() => {
                onConfirm?.();
              }}
            >
              确定
            </IonButton>
          </IonButtons>
        </IonToolbar>
      )}
      <IonContent>{children}</IonContent>
    </IonModal>
  );
});
Modal.displayName = "Modal";

export default memo(Modal);
