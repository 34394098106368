import React, { useState, useEffect, useCallback, useMemo } from "react";
import { getDevice } from "../../shared/getDevice";

type INavigator = Navigator & {
  connection: {
    effectiveType: string;
    addEventListener: (type: string, listener: EventListener) => void;
    removeEventListener: (type: string, listener: EventListener) => void;
  };
};

const { ios } = getDevice();

/**
 * 网络状态检测
 * window.navigator.connection is not supported in iOS
 */
const NetworkStatus: React.FC<React.PropsWithChildren> = (props) => {
  const [isOffline, setIsOffline] = useState(!window.navigator?.onLine);
  const [effectiveType, setEffectiveType] = useState(
    (window.navigator as INavigator)?.connection?.effectiveType || "unknown",
  );

  const updateNetworkStatus = useCallback(() => {
    setIsOffline(!window.navigator?.onLine);
    setEffectiveType((window.navigator as INavigator)?.connection?.effectiveType);
  }, []);

  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);
    (window.navigator as INavigator)?.connection?.addEventListener("change", updateNetworkStatus);

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
      (window.navigator as INavigator)?.connection?.removeEventListener(
        "change",
        updateNetworkStatus,
      );
    };
  }, []);

  const renderChildren = useMemo(() => props.children, [props.children]);

  if (ios) return renderChildren;

  return (
    <>
      {isOffline ? (
        <div style={{ color: "red" }}>You are currently offline.</div>
      ) : (
        <>
          {effectiveType === "2g" ? (
            <p style={{ color: "orange" }}>Your network connection is slow.</p>
          ) : (
            renderChildren
          )}
        </>
      )}
    </>
  );
};

export default NetworkStatus;
