import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import sersorsTrack from "shared/shenceTrack";
import { isProductionMode } from "./shared/env";
import { sentryInit, SWRConfigProvider } from "@union/core";

import VConsole from "vconsole";

import "./styles";

const isProduction = isProductionMode();
sentryInit({
  enabled: isProduction,
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  environment: import.meta.env.PUBLIC_ENV_MODE,
});
isProduction && sersorsTrack(import.meta.env.PUBLIC_SHENCE_URL);

const rootEl = document.getElementById("root");
if (rootEl) {
  new VConsole();
  const root = ReactDOM.createRoot(rootEl);
  root.render(
    <React.StrictMode>
      <SWRConfigProvider>
        <App />
      </SWRConfigProvider>
    </React.StrictMode>
  );
}
