import Sentry from ".";
import { isError, isObject, parseTime } from "./util.ts";

export enum NameMenu {
  PromiseError = "PromiseError",
  InterfaceError = "InterfaceError",
  LoadSourceError = "LoadSourceError",
  AsyncError = "AsyncError",
  InterfaceWarn = "InterfaceWarn",
  Error = "Error",
  Info = "Info",
}

interface ExceptionOption {
  tags?: {
    errorType?: number;
    errorObj?: Error;
    apiUrl?: string;
    errorLevel?: string;
    sourceUrl?: string;
  };
  extra?: {
    [x: string]: any;
    apiTime?: number | string;
    body?: any;
    respCode?: number | string;
    respData?: any;
    errorNative?: any;
    traceId?: string;
    userName?: string;
  };
}

interface InterfaceExceptionOption {
  errorType?: number;
  errorObj?: any;
  respCode?: number;
  respData?: any;
  body?: any;
  apiUrl?: string;
  apiTime?: number | string;
  errorLevel?: string;
  traceId?: string;
  apiName?: string;
  userName?: string;
  env: string;
}

/**
 * 定义自己的错误类型
 * @param {*} message 错误信息
 * @param {*} name 错误类型
 * NameMenu = ['PromiseError', 'InterfaceError', 'LoadSourceError', 'AsyncError', 'CorsError', 'InterfaceWarn']
 */
export class CustomerError extends Error {
  constructor(message: string, name: NameMenu) {
    super(message);
    this.name = name;
    // v8环境才有
    // Error.captureStackTrace(this,CustomerError);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Object.prototype.hasOwnProperty.call(Error, "captureStackTrace")
      ? Error.captureStackTrace(this, CustomerError)
      : (this.stack = new Error().stack || "");
  }
}

type LimitErrorCnt<T> = {
  [K in keyof T]: number;
};
type CaptureOptions = {
  err: any;
  type: number;
  opts: ExceptionOption;
  limitErrorCnt?: LimitErrorCnt<number> | number;
  env: string;
  userId?: string;
};
/**
 * 封装上报错误接口
 * @param options
 */
export const captureException = (options: CaptureOptions) => {
  const { err, type, opts, limitErrorCnt, env } = options;
  let errObj!: Record<string, any>;
  if (err instanceof Error) {
    errObj = err;
  } else if (err instanceof Object) {
    errObj = new Error(err.message);
    Object.keys(err).forEach((key) => {
      errObj[key] = err[key];
    });
  } else if (typeof err === "string") {
    errObj = new Error(err);
  }
  let errorType!: string;
  let isSendEmail = 0;
  const errorCnt: Record<string, any> = {};
  if (!errorCnt[type]) {
    errorCnt[type] = 0;
  }
  switch (type) {
    case 1:
      errorType = "promise错误";
      isSendEmail = 1;
      errObj.name = "PromiseError";
      break;
    case 2:
      errorType = "页面级错误";
      isSendEmail = 1;
      errObj.name = "PageError";
      break;
    case 3:
      errorType = "接口错误";
      isSendEmail = 1;
      errObj.name = "FetchError";
      break;
    case 4:
      errorType = "加载资源错误";
      break;
    case 5:
      errorType = "异步错误";
      isSendEmail = 1;
      errObj.name = "AsyncError";
      break;
    case 6:
      errorType = "跨域脚本错误";
      break;
    case 7:
      errorType = "组件级错误";
      errObj.name = "ComponentError";
      break;
    case 8:
      errorType = "接口警告";
      isSendEmail = 1;
      errObj.name = "InterfaceWarn";
      break;
    case 9:
      errorType = "XSS攻击";
      isSendEmail = 1;
      errObj.name = "XSSError";
      break;
    default:
      errorType = "普通统计";
      isSendEmail = 1;
      errObj.name = "FetchInfo";
      break;
  }
  if ([1, 2, 3, 5].indexOf(type) < 0) {
    ++errorCnt[type];
    const limit = typeof limitErrorCnt === "object" ? limitErrorCnt[type] || 3 : limitErrorCnt || 3;
    isSendEmail = errorCnt[type] > limit ? 1 : 0;
  }
  const tagsDefault = {
    errorType,
    errInfo: errObj && errObj.message,
    env: env || "development",
    isSendEmail,
    href: window.location.href,
    userId: options?.userId || "",
  };
  const date = parseTime(new Date(), "{y}-{m}-{d}");
  Sentry.captureException(errObj, {
    // @ts-ignore
    tags: Object.assign(tagsDefault, opts.tags || {}),
    extra: {
      ...(opts.extra || {}),
    },
    fingerprint: [date, env, errorType, errObj && errObj.message],
  });
};

/**
 * 接口报错上报
 * @param {*} opts
 */
export const catchError = (opts: InterfaceExceptionOption & Pick<CaptureOptions, "env">) => {
  const {
    errorType = 3,
    errorObj,
    respCode,
    respData,
    body,
    apiUrl,
    apiTime,
    errorLevel,
    traceId,
    apiName,
    userName,
    env,
  } = opts;
  const option = {
    tags: {
      errorType,
      apiUrl,
      errorLevel,
      apiName,
      userName,
    },
    extra: {
      apiTime: `${apiTime}ms`,
      body,
      respCode,
      respData,
      traceId,
      apiName,
      userName,
    },
  };
  captureException({
    err: errorObj,
    type: errorType,
    opts: option,
    limitErrorCnt: 3,
    env,
  });
};

export const captureInterfaceError = async (
  response: Response,
  opt: {
    apiTime: number;
    message: string;
    errorLevel: "error" | "info" | "warn";
    body?: Record<string, any>;
    traceId: string;
    apiName?: string;
    userName?: string;
    resJson?: Record<string, any>;
  } & Pick<CaptureOptions, "env">,
) => {
  const { status, url } = response;
  const errorInfo: InterfaceExceptionOption = {
    errorType: opt.errorLevel === "error" ? 3 : opt.errorLevel === "info" ? 0 : 8,
    errorLevel: opt.errorLevel,
    errorObj: new CustomerError(
      opt.message,
      opt.errorLevel === "error"
        ? NameMenu.InterfaceError
        : opt.errorLevel === "info"
          ? NameMenu.Info
          : NameMenu.InterfaceWarn,
    ),
    respCode: status,
    respData: {},
    body: opt?.body || {},
    apiUrl: url,
    apiTime: opt.apiTime,
    traceId: opt.traceId,
    apiName: opt.apiName,
    userName: opt?.userName || "",
    env: opt.env,
  };
  try {
    errorInfo.respData = opt?.resJson || {};
  } catch (e: any) {
    errorInfo.respData = e;
  }
  catchError(errorInfo);
};

/**
 * 资源错误上报
 */
export function captureSourceLoad(options: Pick<CaptureOptions, "env">) {
  window.addEventListener(
    "error",
    (event) => {
      // 过滤js error
      const target = event.target || event.srcElement;
      const isElementTarget =
        target instanceof HTMLScriptElement ||
        target instanceof HTMLLinkElement ||
        target instanceof HTMLImageElement;
      if (!isElementTarget) return false;
      // 上报资源地址
      const url =
        (target as HTMLScriptElement | HTMLImageElement).src || (target as HTMLLinkElement).href;
      const err = new CustomerError(
        `${(target as HTMLElement).tagName} 资源 ${url} 加载错误`,
        NameMenu.LoadSourceError,
      );
      captureException({
        err,
        type: 4,
        env: options.env,
        opts: {
          tags: {
            sourceUrl: url,
          },
        },
      });
    },
    true,
  );
}

/**
 * 拦截console.error
 */
export function rewriteConsoleError(options: Pick<CaptureOptions, "env">) {
  const nativeConsoleError = window.console.error;
  window.console.error = function (...args: any[]) {
    // console error 要统一上报则 输出args[0]制定类型 logError
    if (args[0] === "XSSError") {
      captureException({
        err: {
          message: "XSSError",
          stack: args[1].stack || (args[1].error && args[1].error.stack),
          errorNative: args[1],
        },
        type: 9,
        opts: {
          extra: {
            errorNative: args[1],
          },
        },
        env: options.env,
      });
    } else if (args[0] === "logError") {
      args.forEach((item) => {
        let errorObj;
        if (isObject(item) || isError(item)) {
          errorObj = {
            message: item.message || "",
            stack: item.stack || (item.error && item.error.stack),
            errorNative: item,
          };
        } else {
          errorObj = {
            message: JSON.stringify(item),
            stack: JSON.stringify(item),
            errorNative: item,
          };
        }
        captureException({
          err: errorObj,
          type: 2,
          opts: {
            extra: {
              errorNative: errorObj.errorNative,
            },
          },
          env: options.env,
        });
      });
    }
    nativeConsoleError.apply(this, args);
  };
}
