/**
 * 解析页面组件路径,生成路由对应路径
 * @example
 * 例如将 ./dashboard/page.tsx 解析成 /dashboard
 * @param path
 */
export const resolvePagePath = (path: string) => {
  return path.replace(/\.\/(.*)page\.tsx/, "/$1").replace(/\/$/, "");
};
/**
 * 解析路由配置文件路径, 与路由路径相对应
 * @example
 * 例如将 ./dashboard/route.meta.ts 解析成 /dashboard
 * @param path
 */
export const resolveRouteConfigPath = (path: string) => {
  return path.replace(/\.\/(.*)route\.meta\.ts/, "/$1").replace(/\/$/, "");
};
/**
 * 解析动态路由参数，
 * @example
 * 将 /operation/orderDetail/[id] 解析成 /operation/orderDetail/:id
 * @param path
 */
export const resolveRouteParams = (path: string) => path.replace(/\[([^\]]+)]/g, ":$1");
