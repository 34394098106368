import { getWindow, getDocument } from "ssr-window";

let support: {
  touch: boolean;
  pointerEvents: boolean;
  passiveListener: boolean;
  intersectionObserver: boolean;
};

function calcSupport() {
  const window = getWindow();
  const document = getDocument();

  return {
    touch: !!(
      "ontouchstart" in window ||
      ((window as any).DocumentTouch && document instanceof (window as any).DocumentTouch)
    ),

    pointerEvents:
      !!(window as any).PointerEvent &&
      "maxTouchPoints" in window.navigator &&
      window.navigator.maxTouchPoints >= 0,

    passiveListener: (function checkPassiveListener() {
      let supportsPassive = false;
      try {
        const opts = Object.defineProperty({}, "passive", {
          // eslint-disable-next-line
          get() {
            supportsPassive = true;
          },
        });
        window.addEventListener("testPassiveListener", () => {}, opts);
      } catch (e) {
        console.log(e);
        // No support
      }
      return supportsPassive;
    })(),

    intersectionObserver: (function checkObserver() {
      return "IntersectionObserver" in window;
    })(),
  };
}

function getSupport() {
  if (!support) {
    support = calcSupport();
  }
  return support;
}

export { getSupport };
