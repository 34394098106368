import type { FC } from "react";

const ScreenLoading: FC<{ text?: string }> = ({ text = "加载中" }) => {
  return (
    <div className="spinner-center">
      <div className="spinner">
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
        <div className="spinner-blade" />
      </div>
      <span>{text}</span>
    </div>
  );
};

export default ScreenLoading;
