import { v4 as uuidV4 } from "uuid";
import { nonNullableObject } from "./utils";

import Sentry from "../sentry";
import { captureInterfaceError } from "../sentry/capture";

// polyfill for fetch
import "whatwg-fetch";

export type RequestOptions<U> = {
  /**
   * 是否获取原始响应数据，例如下载文件
   */
  getOriginResponse?: boolean;
  /**
   * 接口请求成功时的提示信息，一些涉及到用户操作的接口请求，需要提示用户操作结果
   */
  successMessage?: string;
  /**
   * 是否应用 API 统一前缀，默认为 true
   */
  usePrefix?: boolean;
  /**
   * 请求类型, 用于区分不同的请求，接口文档中有说明
   */
  actionType?: U;
  /**
   * 请求超时时间
   */
  timeout?: number;
  /**
   * 自定义的 AbortController
   */
  abortController?: AbortController;
  /**
   * 是否允许重复请求
   */
  allowRepeat?: boolean;
  /**
   * 是否显示 Loading
   */
  allowLoading?: boolean;
  /**
   * Loading 提示语
   */
  loadingText?: string;
} & Omit<RequestInit, "signal">;
export type RequestData = Record<string, any> | FormData | null;
export type HttpResponse<T = Record<string, any>> = {
  code: string;
  msg: string;
  data: T;
  success: boolean;
};

export const errorMsgRecord: Record<number, string> = {
  500: "啊哦，服务出错啦，请稍后再试",
  502: "啊哦，服务出错啦，请稍后再试",
  404: "服务找不到，请联系网站管理员",
  403: "服务请求出错啦",
  401: "身份信息已过期",
};

export interface HttpClientInterface {
  BASE_URL: string;
  environment: string;
  userName: string;
  requestFailHandler(response: Response, errMsg: string): Promise<void>;
  requestSuccessHandler(response: Response, requestOptions: RequestInit): Promise<void>;
}

export default abstract class HttpClient {
  /**
   * 需要重定向的状态码
   */
  redirectCodes = [401, 301, 302];
  /**
   * 请求地址前缀
   */
  abstract BASE_URL: string;
  /**
   * 当前环境
   */
  abstract environment: string;
  /**
   * 当前用户
   */
  abstract userName: string;

  /**
   * 是否上报异常到 sentry
   */
  abstract sentryReport: boolean;

  static instance: HttpClient;
  /**
   * 请求池
   * @private
   */
  private requestPool: {
    url: string;
    status: "pending" | "finish";
    abortController: AbortController;
  }[] = [];
  /**
   * 请求中的数量
   * @protected
   */
  protected loadingCount = 0;

  protected constructor() {
    if (!HttpClient.instance) {
      HttpClient.instance = this;
    }
    return HttpClient.instance;
  }

  /**
   * fetch request
   * @param url - 请求地址
   * @param data - 请求数据
   * @param options - 请求配置，同 fetch 的 RequestInit
   * @example
   * options.getOriginResponse=true 获取原始响应数据，例如下载文件
   */
  public request(
    url: string,
    data: RequestData,
    options: Omit<RequestOptions<string>, "getOriginResponse"> & {
      getOriginResponse: true;
    },
  ): Promise<Response>;

  /**
   * fetch request
   * @param url - 请求地址
   * @param data - 请求数据
   * @param options - 请求配置，同 fetch 的 RequestInit
   */
  public request<T = any>(
    url: string,
    data?: RequestData,
    options?: RequestOptions<string>,
  ): Promise<HttpResponse<T>>;

  /**
   * fetch request
   * @param url - 请求地址
   * @param data - 请求数据
   * @param options - 请求配置，同 fetch 的 RequestInit
   */
  public async request<T = any>(
    url: string,
    data: RequestData = {},
    options?: RequestOptions<string>,
  ) {
    const {
      actionType,
      usePrefix = true,
      successMessage,
      timeout = 30000,
      allowRepeat = true,
      getOriginResponse = false,
      allowLoading = true,
      ...userOptions
    } = options || {};

    const requestId = uuidV4();
    const fetchStartTime = new Date().getTime();

    if (allowLoading) {
      this.loadingCount++;
    }

    const requestOptions: RequestInit = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      ...userOptions,
    };
    const customRequestOptions = await this.beforeRequest({
      ...options,
      requestId,
    });
    Object.assign(requestOptions, customRequestOptions);

    if (!requestOptions?.headers || Object.keys(requestOptions.headers).length === 0) {
      requestOptions.headers = {};
    }
    if (requestOptions.headers) {
      // @ts-ignore
      requestOptions.headers["RequestId"] = requestId;
    }

    /**
     * 处理 body 数据
     */
    if (requestOptions.method?.toUpperCase() !== "GET" && data) {
      if (data instanceof FormData) {
        requestOptions.body = data;
      }
      if (Object.keys(data).length > 0 && !(data instanceof FormData)) {
        requestOptions.body = JSON.stringify(nonNullableObject(data));
      }
    }

    /**
     * 处理请求地址
     */
    let requestURL = url;
    if (requestOptions.method?.toUpperCase() === "GET" && data) {
      const params = new URLSearchParams(data as Record<string, any>);
      requestURL = this.queryParamsConvertToURL(requestURL, params.toString());
    }

    const completeURL = usePrefix ? `${this.BASE_URL}${requestURL}` : requestURL;
    const httpURL = this.convertActionType(completeURL, actionType);

    const abortController = userOptions?.abortController ?? new AbortController();
    requestOptions.signal = abortController.signal;
    const timer = setTimeout(() => {
      abortController.abort({ status: 400 });
      this.requestPool = this.requestPool.filter((item) => item.url !== httpURL);
      if (abortController.signal.aborted) {
        this.requestTimeoutHandler({
          ...requestOptions,
          url,
        });
      }
    }, timeout);

    const requestPoolKey = this.handleRequestPoolKey(httpURL, data);
    this.requestPool.push({
      url: requestPoolKey,
      status: "pending",
      abortController,
    });
    if (!allowRepeat) {
      // 不允许重复请求则取消之前的请求，如果存在的话，只保留最新的请求
      this.cancelRequest(requestPoolKey);
    }

    return Sentry.startSpan(
      {
        op: "http.client",
        name: `${httpURL}`,
      },
      async (span) => {
        try {
          const parsedURL = new URL(completeURL, location.origin);
          span.setAttribute("server.address", parsedURL.hostname);
          span.setAttribute("server.port", parsedURL.port || undefined);
          if (!(requestOptions.body instanceof FormData)) {
            span.setAttribute("http.query", JSON.stringify(requestOptions.body));
          } else {
            span.setAttribute(
              "http.query",
              JSON.stringify(Array.from(requestOptions.body.entries())),
            );
          }

          const startTime = performance.now(); // 开始计时
          const response = await fetch(httpURL, requestOptions);
          const endTime = performance.now(); // 结束计时
          console.log(httpURL, `请求耗时：${(endTime - startTime).toFixed()} 毫秒`);
          span.setAttribute("http.response.status_code", response.status);
          span.setAttribute(
            "http.response_content_length",
            Number(response.clone().headers.get("content-length")),
          );

          if (!response.ok) {
            if (!this.redirectCodes.includes(+response.status) && this.sentryReport) {
              // sentry 异常上报(排除重定向状态码) ------- start
              const apiTime = new Date().getTime() - fetchStartTime;
              const message = `接口${httpURL} 报错: ${response.statusText}`;
              try {
                captureInterfaceError(response, {
                  apiTime,
                  message,
                  userName: this.userName,
                  errorLevel: "error",
                  body: requestOptions.body as Record<string, any>,
                  traceId: requestId,
                  resJson: await response.json(),
                  env: this.environment,
                }).then();
              } catch (e) {
                console.log(e);
              }
              // sentry 异常上报 ------- end
            }

            if (!this.redirectCodes.includes(+response.status)) {
              const errorMsg = errorMsgRecord[response.status];
              await this.requestFailHandler(response, {
                ...requestOptions,
                requestId,
                errorMsg,
              });
            }
            return response;
          }
          if (!getOriginResponse) {
            await this.requestSuccessHandler(response.clone(), {
              ...requestOptions,
              requestId,
            });
            const result = await this.handleResponse<T>({
              response,
              successMessage,
              traceId: requestId,
              requestInit: {
                url: httpURL,
                body: requestOptions.body as Record<string, any>,
              },
              fetchStartTime,
            });
            return result as HttpResponse<T>;
          }
          return response;
        } catch (e: any) {
          // 取消的请求不做任何操作
          if (e.name === "AbortError") {
            return this.requestCancelHandler({
              ...requestOptions,
              requestId,
            });
          }
          const errorMsg = e.toString();
          const response = new Response("error", {
            status: 500,
            statusText: errorMsg,
          });
          await this.requestFailHandler(response, {
            ...requestOptions,
            requestId,
            errorMsg,
          });
          throw e;
        } finally {
          clearTimeout(timer);
          this.deleteRequest();
          if (allowLoading) {
            this.loadingCount = Math.max(0, this.loadingCount - 1);
          }
        }
      },
    );
  }

  private async handleResponse<T>(options: {
    response: Response;
    successMessage?: string;
    traceId: string;
    fetchStartTime?: number;
    requestInit: { url: string; body?: Record<string, any> };
  }) {
    const { response, successMessage, traceId, fetchStartTime, requestInit } = options ?? {};
    const result: HttpResponse<T> = await response.json();
    if ("code" in result && (result.code !== "0000" || result.code === null)) {
      // sentry 异常上报 ------- start
      const apiTime = new Date().getTime() - (fetchStartTime ?? 0);
      const message = `接口${requestInit.url} 报错: ${result.msg}`;
      if (this.sentryReport) {
        try {
          captureInterfaceError(response, {
            apiTime,
            message,
            traceId,
            userName: this.userName,
            errorLevel: "error",
            body: requestInit.body,
            resJson: result,
            env: this.environment,
          }).then();
        } catch (e) {
          console.log(e);
        }
      }
      // sentry 异常上报 ------- end
      this.showToast(result.msg);
      return result;
    }
    successMessage && this.showToast(successMessage);
    return result;
  }

  /**
   * 将查询参数转换为 URL
   * @param url
   * @param paramsStr
   */
  private queryParamsConvertToURL(url: string, paramsStr?: string) {
    if (!paramsStr) return url;
    return `${url}${url.includes("?") ? "&" : "?"}${paramsStr}`;
  }

  /**
   * url 拼接 actionType
   * @param url
   * @param actionType
   */
  private convertActionType(url: string, actionType?: string) {
    if (!actionType) {
      return url;
    }
    return this.queryParamsConvertToURL(url, `actionType=${actionType}`);
  }

  private handleRequestPoolKey(url: string, data?: RequestData) {
    if (!data) return url;
    if (data instanceof FormData) {
      return `${url}${JSON.stringify(Array.from(data.entries()))}`;
    }
    return `${url}${JSON.stringify(data)}`;
  }

  /**
   * 取消请求
   * @param url
   */
  private cancelRequest(url: string) {
    const pendingRequests = this.requestPool.filter(
      (request) => request.url === url && request.status === "pending",
    );

    // 保留最新的请求，即 `pendingRequests` 列表中的最后一个，取消其他的
    pendingRequests.slice(0, -1).forEach((request) => {
      request.status = "finish";
      request.abortController.abort({ status: 400 });
    });
  }

  private deleteRequest() {
    this.requestPool = this.requestPool.filter((item) => item.status === "pending");
  }

  /**
   * 取消所有请求
   */
  public cancelAllRequest() {
    this.requestPool.forEach((request) => {
      if (request.status === "pending") {
        request.abortController.abort({ status: 400 });
      }
    });
    this.requestPool = [];
  }

  abstract showToast(message: string): void;

  /**
   * 请求失败
   * @param response
   * @param requestOptions
   */
  abstract requestFailHandler(
    response: Response,
    requestOptions: RequestInit & { requestId: string; errorMsg: string },
  ): Promise<void>;

  /**
   * 请求取消，主动取消相同请求
   * @param requestInfo
   */
  abstract requestCancelHandler(
    requestInfo: RequestOptions<string> & { requestId: string },
  ): Promise<void>;

  abstract requestTimeoutHandler(
    requestOptions?: RequestOptions<string> & { url: string },
  ): Promise<void>;

  /**
   * 请求响应成功
   * @param response
   * @param requestOptions
   */
  abstract requestSuccessHandler(
    response: Response,
    requestOptions: RequestInit & { requestId: string },
  ): Promise<void>;

  /**
   * 请求前拦截
   * @param options
   */
  abstract beforeRequest(
    options?: RequestOptions<string> & { requestId: string },
  ): Promise<RequestOptions<string>>;
}
